<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{ errors, invalid, validated }">
    <div :class="[inputStyle === 'normal' ? '' : 'app-form-group row', 'form-group', boundaryClass]">
      <label v-if="label && label.length" :class="[
            inputStyle === 'normal' ? '' : ('col-md-' + (colLabel ? colLabel : '4')),
             'input-label control-label p-0 p-r-5',
                 labelClass]">
        {{ label }}
        <span class="color-active"
              v-if="label && label.length && rules && rules.includes('required') && !rules.includes('required_if')">＊</span>
      </label>
      <div v-if="colInput || colLabel"
           :class="'col-md-' + (12 - (colInput ? colInput : 8) - (colLabel ? colLabel : 4))">
      </div>
      <div
        :class="[
          inputStyle === 'normal' ? '' : ('col-md-' + (colInput ? colInput : '8')),
          'form-group m-b-0 p-0',
          invalid && validated ? 'has-error' : '',
          disabled ? 'form-disabled' : ''
        ]">
        <div :class="[$slots.append ? 'input-group' : '']" style="width: 100%">
          <input v-if="vcleave" :type="type"
                 :class="['form-control', invalid && validated ? 'error' : '']"
                 :autocomplete="autocomplete || 'off'"
                 :value="value"
                 @blur="blur"
                 @input="$emit('input', $event.target.value)"
                 :disabled="disabled"
                 :maxlength="maxLength"
                 v-cleave="vcleave"
          >
          <input v-else :type="type"
                 :class="['form-control', invalid && validated ? 'error' : '']"
                 :autocomplete="autocomplete || 'off'"
                 :value="value"
                 @blur="blur"
                 @input="$emit('input', $event.target.value)"
                 :disabled="disabled"
                 :maxlength="maxLength"
          >
          <div v-if="$slots.append" class="input-group-append">
            <span class="input-group-text text-dark bg-white">
              <slot name="append"></slot>
            </span>
          </div>
        </div>
      </div>
      <label v-if="invalid && validated" class="error app-input-error-label-2">{{ errors[0] }}</label>
    </div>
  </ValidationProvider>
</template>
<script>
export default {
  props: [
    'name',
    'label',
    'type',
    'rules',
    'blur',
    'value',
    'disabled',
    'autocomplete',
    'inputStyle',
    'boundaryClass',
    'labelClass',
    'colLabel',
    'colLabel',
    'colInput',
  ],
  data() {
    return {
      vcleave: false,
      maxLength: false,
    }
  },
  mounted() {
    if (this.type && this.type.includes('number-length:')) {
      const length = this.type.split(':')[1];
      this.vcleave = {
        blocks: [parseInt(length)],
        numericOnly: true,
      }
    }
    switch (this.type) {
      case 'bigint': {
        this.vcleave = {
          blocks: [18],
          numericOnly: true,
        }
        break;
      }
      case 'int': {
        this.vcleave = {
          blocks: [9],
          numericOnly: true,
        }
        break;
      }
      case 'digits': {
        this.vcleave = {
          blocks: [191],
          numericOnly: true,
        }
        break;
      }
      case 'time': {
        this.vcleave = {
          time: true,
          timePattern: ['h', 'm']
        }
        break;
      }
      case 'email':
      case 'string': {
        this.maxLength = 191
        break;
      }
    }
  }
}
</script>
